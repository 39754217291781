import React, { useState } from "react";
import { RichText, Link } from "prismic-reactjs";
import { Carousel } from "react-responsive-carousel";
import linkResolver from "./../helpers/linkResolver";
import isValidLink from "./../helpers/isValidLink";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as IconChecked } from "../images/icons/icon-checked.svg";
import { ReactComponent as IconUnchecked } from "../images/icons/icon-unchecked.svg";
import { ReactComponent as IconAM } from "../images/icons/icon-am.svg";
import { ReactComponent as IconPM } from "../images/icons/icon-pm.svg";
import { ReactComponent as ReliefIcon } from "../images/icons/relief-icon.svg";
import { ReactComponent as RestIcon } from "../images/icons/rest-icon.svg";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as TincturesIcon } from "../images/icons/tinctures-icon.svg";
import { ReactComponent as CapsulesIcon } from "../images/icons/capsules-icon.svg";
import { ReactComponent as RSOsIcon } from "../images/icons/rso-icon.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ProductsSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const [selectedBenefit, setBenefit] = useState("All");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedMood, setSelectedMood] = useState(false);

  const handleNavClick = (index, benefit) => {
    setCurrentSlide(index);
    setBenefit(benefit);
  };

  const useStyles = makeStyles({
    text: {
      color: selectedMood && selectedMood.color,
      "& a": {
        fontWeight: "bold",
        textDecoration: "underline",
      },
      "& svg path": {
        fill: selectedMood && selectedMood.color,
      },
      "& svg polygon": {
        fill: selectedMood && selectedMood.color,
      },
      "& svg rect": {
        fill: selectedMood && selectedMood.color,
      },
    },
  });

  const classes = useStyles();

  const extra = isMobile ? 0 : 1;

  const renderNavigation = (benefits) => {
    return benefits.map((el, i) => (
      <span
        key={i}
        onClick={() => {
          handleNavClick(i + extra, el.title[0].text);
          setSelectedMood(false);
        }}
        className={selectedBenefit === el.title[0].text ? "active" : null}
        style={{ width: isMobile ? "calc(100% / 3)" : "25%" }}
      >
        {el.title[0].text}
      </span>
    ));
  };

  const renderBenefitIcons = (benefits) => {
    return benefits.map((el, i) => (
      <span onClick={() => handleNavClick(i + 1, el.title[0].text)}>
        <img key={i} src={el.image_1.url} alt="Products"></img>
        <div className="rich-text center-align">
          <RichText render={el.title} />
        </div>
      </span>
    ));
  };

  const renderFormIcon = (benefit) => {
    if (selectedBenefit === "Tinctures") {
      return <TincturesIcon />;
    } else if (selectedBenefit === "Capsules") {
      return <CapsulesIcon />;
    } else if (selectedBenefit === "RSOs") {
      return <RSOsIcon />;
    } else {
      return null;
    }
  };

  const renderSlides = (benefits) => {
    return benefits.map((el, i) => (
      <div
        key={i}
        className={`benefit-slide ${el.title[0].text} ${
          selectedBenefit === el.title[0].text && "active"
        }`}
      >
        <div className="slide-wrapper">
          <div className="product-image-carousel">
            <Carousel
              showArrows={false}
              showThumbs={true}
              showIndicators={false}
              showStatus={false}
            >
              <div className="product-slide">
                <img src={el.image_1.url} alt="Carousel 1" />
              </div>
              <div className="product-slide">
                <img src={el.image_2.url} alt="Carousel 2" />
              </div>
              <div className="product-slide">
                <img src={el.image_3.url} alt="Carousel 3" />
              </div>
            </Carousel>
          </div>
          <div className="text-content fran">
            <div className="benefit-details">
              <div className="benefit-icons">
                {el.relief && (
                  <div
                    className={`detail ${
                      selectedMood && selectedMood.mood === "relief" && "active"
                    }`}
                  >
                    <div className="icon big">
                      <ReliefIcon
                        onClick={() =>
                          setSelectedMood({
                            content: el.relief_content,
                            mood: "relief",
                            color: "#71a850",
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                {el.rest && (
                  <div
                    className={`detail ${
                      selectedMood && selectedMood.mood === "rest" && "active"
                    }`}
                  >
                    <div className="icon big">
                      <RestIcon
                        onClick={() =>
                          setSelectedMood({
                            content: el.rest_content,
                            mood: "rest",
                            color: "#5b89b4",
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`rich-content ${classes.text}`}>
              <RichText render={el.rich_content} />
            </div>

            <div className={`rich-content ${classes.text}`}>
              {selectedMood && <RichText render={selectedMood.content} />}
            </div>
            <div className={`benefit-details ${classes.text}`}>
              <div className="detail">
                <div className="icon">
                  {el.fast_acting ? <IconChecked /> : <IconUnchecked />}
                </div>
                <span>Fast Acting</span>
              </div>

              <div className="detail">
                <div className="icon">
                  {el.long_lasting ? <IconChecked /> : <IconUnchecked />}
                </div>
                <span>Long Lasting</span>
              </div>

              <div className="detail">
                <div className="icon">
                  {el.medical_strength ? <IconChecked /> : <IconUnchecked />}
                </div>
                <span>Medical Strength</span>
              </div>
              {el.am && (
                <div className="detail">
                  <div className="icon">
                    <IconAM />
                  </div>
                </div>
              )}
              {el.pm && (
                <div className="detail">
                  <div className="icon">
                    <IconPM />
                  </div>
                </div>
              )}
            </div>
            <div className={`form-icons ${classes.text}`}>
              {renderFormIcon(selectedBenefit)}
            </div>
            <div className="buttons">
              {isValidLink(slice.primary.cta_link, slice.primary.cta_text) && (
                <a
                  href={Link.url(slice.primary.cta_link, linkResolver)}
                  className="button"
                >
                  {slice.primary.cta_text[0].text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="benefits-slice standard-padding">
      <div className="wrapper">
        <div className="text-content center-align">
          <RichText render={slice.primary.rich_content} />
        </div>

        <div className="benefits-slider">
          <div className="nav">
            {!isMobile && (
              <span
                className={selectedBenefit === "All" ? "active" : null}
                onClick={() => handleNavClick(0, "All")}
                style={{ width: isMobile ? "auto" : "25%" }}
              >
                All
              </span>
            )}
            {renderNavigation(slice.items)}
          </div>

          {!isMobile ? (
            <Carousel
              showArrows={false}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              className="slider-content"
              selectedItem={currentSlide}
            >
              <div
                className={`benefit-slide All ${
                  selectedBenefit === "All" && "active"
                }`}
              >
                {renderBenefitIcons(slice.items)}
              </div>
              {renderSlides(slice.items)}
            </Carousel>
          ) : (
            <Carousel
              showArrows={false}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              className="slider-content"
              selectedItem={currentSlide}
            >
              {renderSlides(slice.items)}
            </Carousel>
          )}
        </div>
        {currentSlide === 0 && !isMobile && (
          <div className="buttons">
            {isValidLink(slice.primary.cta_link, slice.primary.cta_text) && (
              <a
                href={Link.url(slice.primary.cta_link, linkResolver)}
                className="button"
              >
                {slice.primary.cta_text[0].text}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
