import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import Cookie from "js-cookie";
import Routes from "./routes";
import RenderMetaTags from "./components/SEO";
import AgeGate from "./components/AgeGate";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import {ThemeProvider} from "@material-ui/core/styles";
import {theme} from "./theme";
import "./css/styles.css";
import Dialogs from "./components/Dialog";

var pjson = require("../package.json");

console.log(`Version: ${pjson.version}`);

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isPrivacyPolicy =
    location.pathname === "/privacy-policy" ||
    location.pathname === "/terms-of-service";

  const isStaging =
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("crescolabsdev");

  const over21cookie = Cookie.get("over21") || false;
  const [ageVerified, setAgeVerified] = useState(over21cookie);
  const [loading, setLoading] = useState(true);
  const enableAnalytics = useSelector((state) =>
    get(state, "cookieCompliance.analytics", false)
  );

  const handleLoading = (value) => {
    if (value === "yas") {
      setTimeout(() => {
        setLoading(value);
      }, 200);
    } else {
      setLoading(value);
    }
  };

  const verifyAge = () => {
    setAgeVerified(true);
  };

  useEffect(() => {
    dispatch({
      type: "INITIALIZE_APP",
    });
    dispatch({
      type: "ACCESSIBILITY/CHECK",
    });
  }, [dispatch]);

  useEffect(() => {
    if (isPrivacyPolicy) {
      setAgeVerified(isPrivacyPolicy);
    } else {
      setAgeVerified(over21cookie);
    }
  }, [isPrivacyPolicy, over21cookie]);

  useEffect(() => {
    handleLoading(true);
  }, [location.pathname]);

  useEffect(() => {
    if (enableAnalytics) {
      window.analytics.page();
    }
  }, [location.pathname, enableAnalytics]);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop>
        <Dialogs />
        {!ageVerified && <AgeGate verifyAge={verifyAge} />}
        <RenderMetaTags pathname={location.pathname} />
        <Header activePage={location.pathname} />
        <div className={"no-flick"}>
          <Routes
            loading={handleLoading}
            isStaging={isStaging}
            animation={loading}
          />
        </div>
        <Footer />
      </ScrollToTop>
    </ThemeProvider>
  );
}
