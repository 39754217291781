import React, {useState, useEffect} from "react";
import PrismicRichText from "../components/PrismicRichText";
import {Carousel} from "react-responsive-carousel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as FormsIcon} from "../images/icons/forms-icon.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Link from "../components/Link";
import {Button} from "@material-ui/core";
import {get} from "lodash";

export default function BenefitsSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const [selectedBenefit, setBenefit] = useState("All");
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNavClick = (index, benefit) => {
    setCurrentSlide(index);
    setBenefit(benefit);
  };

  console.log(slice, "hi");

  const extra = isMobile ? 0 : 1;

  useEffect(() => {
    setCurrentSlide(0);
  }, [isMobile]);

  const slideColor = () => {
    let color = false;
    if (!isMobile && currentSlide === 0) {
      color = "#8b8b8b";
    } else if (isMobile) {
      color = slice.items[currentSlide].color;
    } else {
      color = slice.items[currentSlide - 1].color;
    }
    return color;
  };

  const useStyles = makeStyles({
    text: {
      color: slideColor(),
      "& a": {
        fontWeight: "bold",
        textDecoration: "underline",
      },
      "& svg path": {
        fill: slideColor(),
      },
      "& svg polygon": {
        fill: slideColor(),
      },
      "& svg rect": {
        fill: slideColor(),
      },
    },
  });

  const classes = useStyles();

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
  };

  const renderNavigation = (benefits) => {
    return benefits.map((el, i) => (
      <span
        key={i}
        onClick={() => handleNavClick(i + extra, el.title[0].text)}
        className={selectedBenefit === el.title[0].text ? "active" : null}
      >
        {el.title[0].text}
      </span>
    ));
  };

  const renderBenefitIcons = (benefits) => {
    return benefits.map((el, i) => (
      <span onClick={() => handleNavClick(i + 1, el.title[0].text)} key={i}>
        <img src={el.icon.url} alt="Products"></img>
      </span>
    ));
  };

  const renderSlides = (benefits) => {
    return benefits.map((el, i) => (
      <div key={i} className={`benefit-slide ${el.title[0].text}`}>
        <div className="slide-wrapper">
          <div className="benefit-icon">
            <img src={el.icon.url} alt="Products" />
            <p className="fran">{el.benefit[0].text}</p>
          </div>
          <div className="text-content fran">
            <div className={`rich-content ${classes.text}`}>
              <PrismicRichText render={el.rich_content} />
            </div>

            <div className={`form-icons ${classes.text}`}>
              <FormsIcon />
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="benefits-slice standard-padding" style={backgroundStyle}>
      <div className="wrapper">
        <div className="text-content center-align">
          <PrismicRichText render={slice.primary.rich_content} />
        </div>

        <div className="benefits-slider">
          <div className="nav">
            {!isMobile && (
              <span
                className={selectedBenefit === "All" ? "active" : null}
                onClick={() => handleNavClick(0, "All")}
              >
                All
              </span>
            )}
            {renderNavigation(slice.items)}
          </div>

          {!isMobile ? (
            <Carousel
              showArrows={false}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              className="slider-content"
              selectedItem={currentSlide}
            >
              <div
                className={`benefit-slide All ${
                  selectedBenefit === "All" && "active"
                }`}
              >
                {renderBenefitIcons(slice.items)}
              </div>
              {renderSlides(slice.items)}
            </Carousel>
          ) : (
            <Carousel
              showArrows={false}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              className="slider-content"
              selectedItem={currentSlide}
            >
              {renderSlides(slice.items)}
            </Carousel>
          )}
        </div>
        <div className="buttons buttons-container">
          <Link
            to={"/" + get(slice, "primary.cta_link.uid")}
            url={get(slice, "primary.cta_link.url")}
            target={get(slice, "primary.cta_link.target")}
          >
            <Button disableElevation className="button outlined white-bg">
              {get(slice, "primary.cta_text[0].text")}
            </Button>
          </Link>

          <Link
            to={"/" + get(slice, "primary.cta_link_2.uid")}
            url={get(slice, "primary.cta_link_2.url")}
            target={get(slice, "primary.cta_link_2.target")}
          >
            <Button disableElevation className="button solid">
              {get(slice, "primary.cta_text_2[0].text")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
