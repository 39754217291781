import React from "react";
import { Button } from "@material-ui/core";

export default function PDFSection(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
    textAlign: "center",
  };

  const pdfFile = slice.primary.pdf_file.url;

  return (
    <section className="pdf-section standard-padding" style={backgroundStyle}>
      <div className="wrapper">
        <Button
          target="_blank"
          color="primary"
          variant="contained"
          href={pdfFile}
        >
          View Spanish Product Labels
        </Button>
      </div>
    </section>
  );
}
