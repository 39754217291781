import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import Cookie from "js-cookie";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";

export default function AgeGate(props) {
  const dispatch = useDispatch();

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [spanish, toggleSpanish] = useState(false);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.age_gate.loading", true)
  );
  const page = useSelector((state) =>
    get(state, "prismic.age_gate.data", false)
  );

  const logo = get(page, "age_gate[0].logo.url");
  const errorMessage = get(page, "age_gate[0].error_message[0].text");
  const backgroundColor = get(page, "age_gate[0].background_color");
  const backgroundImage = get(page, "age_gate[0].background_image.url");
  const backgroundVideo = get(page, "age_gate[0].background_video.url");
  const textColor = get(page, "age_gate[0].text_color");
  const overlayColor = get(page, "age_gate[0].overlay[0].text");

  const copy = {
    english: {
      headline: "Are You Over 21?",
      body: (
        <>
          Yes, I am at least twenty-one (21) years of age or am otherwise a
          qualified patient. By clicking “I agree” and entering the website, I
          agree to be bound by the{" "}
          <Link to="/terms-of-service">Terms of Service</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </>
      ),
      checkbox: "I Agree",
      button: "Enter Site",
    },
    spanish: {
      headline: "¿Es mayor de 21 años?",
      body: (
        <>
          Sí, tengo al menos veintiún (21) años o soy un paciente calificado. Al
          hacer clic en “Acepto” y entrar en el sitio web, acepto los{" "}
          <Link to="/terms-of-service">Términos de servicio</Link> y la{" "}
          <Link to="/privacy-policy">Política de privacidad</Link>.
        </>
      ),
      checkbox: "Acepto",
      button: "Ingresar al sitio",
    },
  };

  const localizedCopy = spanish ? copy.spanish : copy.english;

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "age_gate",
    });
  }, [dispatch]);

  const handleCheckbox = (e) => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleSwitch = () => {
    toggleSpanish((prev) => !prev);
  };

  const allowPassage = (event) => {
    event.preventDefault();
    if (checkboxChecked) {
      Cookie.set("over21", true, {expires: 3});
      setVerified(true);
      props.verifyAge();
    } else {
      setError(true);
    }
  };

  const backgroundImageStyle = {
    color: textColor,
    backgroundImage: "url(" + backgroundImage + ")",
  };

  const backgroundColorStyle = {
    background: backgroundColor,
  };

  const overlayStyle = {
    background: overlayColor,
  };
  return (
    !pageLoading &&
    !verified && (
      <div id="age-gate" style={backgroundImageStyle}>
        {overlayColor && <div className="overlay" style={overlayStyle}></div>}
        {backgroundVideo && (
          <div className="background-video">
            <video autoPlay playsInline muted loop>
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </div>
        )}

        <div className="age-gate-content" style={backgroundColorStyle}>
          <div className="spanish-toggle mt-10">
            <FormControlLabel
              control={
                <Switch
                  checked={spanish}
                  onChange={handleSwitch}
                  color="primary"
                  className="mr-5"
                />
              }
              label={!spanish ? "ES" : "EN"}
            />
          </div>
          <div className="logo-section">
            {logo && (
              <img className="age-gate-logo" alt="Brand Logo" src={logo} />
            )}
          </div>
          <div className="content-section">
            <h2>{localizedCopy.headline}</h2>
            <p>{localizedCopy.body}</p>
            <form id="age-gate-form" onSubmit={allowPassage}>
              <FormControlLabel
                value="start"
                className="mb-20"
                control={
                  <Checkbox
                    checked={checkboxChecked}
                    onChange={handleCheckbox}
                    color="primary"
                    inputProps={{"aria-label": localizedCopy.checkbox}}
                  />
                }
                label={localizedCopy.checkbox}
                labelPlacement="end"
              />

              {error && !checkboxChecked && (
                <p className="error-message">
                  {errorMessage
                    ? errorMessage
                    : "Please confirm you are over 21 years of age."}
                </p>
              )}
              <br></br>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                {localizedCopy.button}
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
