import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Grid from "@material-ui/core/Grid";

export default function PhotoSection(slice) {
  const renderItems = (items) => {
    return items.map((el, i) => (
      <Grid
        container
        className={`photo-section-item animate ${
          el.section_alignment === "Image / Content" && "lead-image"
        }`}
      >
        <Grid className="image-wrapper" item xs={12} sm={6}>
          <img src={el.image.url} alt="Hero Element" />
        </Grid>
        <Grid className="content-wrapper" item key={i} xs={12} sm={6}>
          <div className="content box-padding">
            <div className="text-content">
              <PrismicRichText render={el.content} />
            </div>
          </div>
        </Grid>
      </Grid>
    ));
  };
  return (
    <section className="photo-section">
      <div className="wrapper">{renderItems(slice.items)}</div>
    </section>
  );
}
