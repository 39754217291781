import axios from "axios";

const makeRequest = (url, apiKey) => {
  const options = {
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(url, options)
    .then((response) => response.data)
    .catch((error) => error);
};

const wholesaleCustomersNear = (city, brand, limit, offset) => {
  let url = "";
  let apiKey = "";
  if (window.location.hostname === "www.chooseremedi.com") {
    url = "https://api.crescolabs.com/p/customers?";

    apiKey = "73jjmpnQVc8JNX0JRMYH85DFkGQVxIXNf1Gfm6Bj";
  } else {
    url = "https://api-stage.crescolabsdev.com/p/customers?";

    apiKey = "jQVtd4jQyQ3mQNkblMWRQ9SFjJJDM3Xc6QDGTZEC";
  }

  const params = `near=${city}&brands=${brand}&limit=${limit}&offset=${offset}`;

  const request = url + params;

  return makeRequest(request, apiKey);
};

export default wholesaleCustomersNear;
