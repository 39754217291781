import React from "react";
import PrismicRichText from "../PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import renderButtons from "../../helpers/renderButtons";

const Hero = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const bgImage = isMobile
    ? props.mobileBackgroundImage
    : props.backgroundImage;

  const backgroundImage = {
    backgroundImage: "url(" + bgImage + ")",
  };

  const backgroundColor = {
    background: props.backgroundColor,
  };

  const backgroundVideo = props.backgroundVideo;

  return (
    <section className="hero-section gray-headlines-and-copy">
      <div className="extra-margin"></div>

      {!backgroundVideo && (
        <div className="background-image" style={backgroundImage}></div>
      )}

      {backgroundVideo && (
        <div className="background-video">
          <video autoPlay playsInline muted loop>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>
      )}
      <div
        className={`wrapper ${
          props.textBoxAlignment === "Right" && "align-right"
        }`}
      >
        <div className="text-content">
          <PrismicRichText render={props.richContent} />
          {props.buttons && props.buttons[0].cta_text[0] && (
            <div className="buttons">{renderButtons(props.buttons)}</div>
          )}
        </div>
      </div>
      <div className="overlay"></div>
      <div className="background-color" style={backgroundColor}></div>
    </section>
  );
};

export default Hero;
