import React, {useEffect, useState} from "react";
import PrismicRichText from "../components/PrismicRichText";
import {useSelector, useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {get} from "lodash";
import Link from "../components/Link";

export default function EducationSlice(slice) {
  const dispatch = useDispatch();
  const [currentPhoto, setCurrentPhoto] = useState(false);

  const postsLoading = useSelector((state) =>
    get(state, "prismic.education_post_list.loading", true)
  );
  const posts = useSelector((state) =>
    get(state, "prismic.education_post_list.data", false)
  );

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_REPEATABLE",
      key: "education_post",
      reduxKey: "education_post_list",
    });
  }, [dispatch]);

  useEffect(() => {
    if (!postsLoading) {
      setCurrentPhoto(posts[0].data.image.url);
    }
  }, [dispatch, postsLoading, posts]);

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
  };

  const handleHover = (image) => {
    setCurrentPhoto(image);
  };

  const renderListView = (posts) => {
    return posts.map((el, i) => (
      <Link
        to={`/education/${el.uid}`}
        key={i}
        className="post"
        onMouseEnter={() => handleHover(el.data.image.url)}
      >
        <h2>{el.data.page_title[0].text}</h2>
        <p>{el.data.excerpt[0].text}</p>
        <span className="button plus">Learn More</span>
      </Link>
    ));
  };

  const renderPhotoView = (posts) => {
    return posts.map((el, i) => (
      <Grid
        key={i}
        container
        className={`photo-section-item animate ${i % 2 !== 0 && "lead-image"}`}
      >
        <Grid className="image-wrapper" item xs={12} sm={6}>
          {el.data.image.url && (
            <img src={el.data.image.url} alt="Hero Element" />
          )}
        </Grid>
        <Grid className="content-wrapper" items xs={12} sm={6}>
          <div className="content box-padding">
            <div className="text-content">
              <h2>{el.data.page_title[0].text}</h2>
              <PrismicRichText render={el.data.excerpt} />
              <Link className="button white-bg" to={`/education/${el.uid}`}>
                Learn More
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    ));
  };

  return slice.primary.type === "List" ? (
    <section className="education-slice standard-padding">
      <div className="wrapper">
        <div className="image-content">
          <img src={currentPhoto} alt="Education Article" />
        </div>
        <div className="text-content" style={backgroundStyle}>
          {!postsLoading && posts && renderListView(posts)}
        </div>
      </div>
    </section>
  ) : (
    <section className="photo-section">
      <div className="wrapper">
        {!postsLoading && posts && renderPhotoView(posts)}
      </div>
    </section>
  );
}
