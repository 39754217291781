import React from "react";
import CookieSettingsLink from "./CookieSettingsLink";
import {useSelector} from "react-redux";
import {get, map} from "lodash";
import PrismicRichText from "../PrismicRichText";
import linkResolver from "./../../helpers/linkResolver";
import AccessibilitySwitch from "../AccessibilitySwitch";
import Link from "../Link";

export default function Footer() {
  const componentLoading = useSelector((state) =>
    get(state, "prismic.footer.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.footer.data", false)
  );

  const backgroundColor = get(component, "background_color");
  const textColor = get(component, "text_color");

  const backgroundStyle = {
    color: textColor,
    background: backgroundColor,
  };

  const year = new Date().getFullYear();

  const renderFooterColumns = (columns) => {
    return columns.map((el, i) => (
      <div key={i} className="footer-section">
        <div className="heading">
          <PrismicRichText
            linkResolver={linkResolver}
            render={el.column_title}
          />
        </div>
        <div className="content">
          <PrismicRichText linkResolver={linkResolver} render={el.content} />
        </div>
      </div>
    ));
  };

  const renderSocialLinks = (socialLinks) => {
    return map(socialLinks, (el, i) => (
      <Link key={i} url={get(el, "link.url")} target="_blank">
        <img src={get(el, "icon.url")} alt="Social Icon" />
      </Link>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer className="standard-padding" style={backgroundStyle}>
        <div className="wrapper">
          <div className="footer-section averta">
            <p>
              <span className="mr-10">Grown by </span>
              <span className="logo-text">CRESCO LABS</span>
            </p>
            <p>
              We’re on a mission to normalize, professionalize, and
              revolutionize cannabis.
            </p>
          </div>
          {renderFooterColumns(component.footer)}
          <div className="footer-section">
            <div className="cookie-managemen mb-10">
              <CookieSettingsLink />
            </div>
            <div className="social">
              <p className="copyright">©{year} Remedi All rights reserved.</p>
              {renderSocialLinks(component.social_links)}
              <div className="accessibility">
                <AccessibilitySwitch />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}
