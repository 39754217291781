import React, {useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import renderButtons from "../../helpers/renderButtons";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import {RichText} from "prismic-reactjs";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ShopPageDialog() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "shop_page_dialog",
    });
  }, [dispatch]);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.shop_page_dialog.loading", true)
  );
  const page = useSelector((state) =>
    get(state, "prismic.shop_page_dialog.data", false)
  );

  const disclaimerCopy = page?.disclaimer_copy;
  const popupCopy = page?.popup_copy;
  const websiteLogo = get(page, "website_logo.url");

  const sunnysideLogo = get(page, "sunnyside_logo.url");
  const sunnysideButtonLink = get(page, "sunny_button_link.url");
  const sunnysideButtonText = get(page, "sunny_button_text[0].text");

  const otherVendorLogo = get(page, "other_vendor_logo.url");
  const otherVendorButtonLink = get(page, "other_vendor_button_link.url");
  const otherVendorButtonText = get(page, "other_vendor_button_text[0].text");

  const handleClose = () => {
    history.push("/");
  };

  const sunnyButton = [
    {
      active: true,
      cta_link: {
        url: sunnysideButtonLink,
        target: "_blank",
      },
      cta_text: sunnysideButtonText,
      button_variant: "contained",
      button_color: "primary",
    },
  ];
  const otherVendorButton = [
    {
      active: true,
      cta_link: {
        url: otherVendorButtonLink,
        target: "_blank",
      },
      cta_text: otherVendorButtonText,
      button_variant: "contained",
      button_color: "primary",
    },
  ];

  if (pageLoading)
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "white",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick="true"
      className="shop-page-dialog"
      style={{
        backgroundColor: "#ffffff",
      }}
      TransitionComponent={Slide}
    >
      <div className="dialog-wrapper">
        <div className="logo-container">
          <img
            src={websiteLogo}
            className="block shop-popoup-logo"
            alt=""
            role="presentation"
          />
          <div className="close">
            <IconButton
              onClick={handleClose}
              className="icon-close"
              aria-label="dialog close"
            >
              <CloseIcon htmlColor="#8a8c8f" />
            </IconButton>
          </div>
        </div>
        <div className="content">
          <RichText render={popupCopy} />
        </div>
        <div className="button-container">
          <div className="shop-link shop-sunny ">
            <img
              src={sunnysideLogo}
              className="block sunny-logo shop-popoup-logo"
              alt=""
              role="presentation"
            />
            {renderButtons(sunnyButton)}
          </div>
          <div className="shop-link shop-jane ">
            <img
              src={otherVendorLogo}
              className="block jane-logo"
              alt=""
              role="presentation"
            />
            {renderButtons(otherVendorButton)}
          </div>
        </div>

        <div className="disclaimer-text ">
          <RichText render={disclaimerCopy} />
        </div>
      </div>
    </Dialog>
  );
}
