import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {get} from "lodash";
import Hero from "../components/Hero";
import Slices from "../slices";
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrismicRichText from "../components/PrismicRichText";

export default function EducationPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingFunc = props.loading;
  const animation = props.animation;

  const whichPage = (path) => {
    return path.split("/")[2];
  };

  const pagePath = whichPage(location.pathname);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.education_post.loading", true)
  );
  const page = useSelector((state) =>
    get(state, "prismic.education_post.data", false)
  );

  const educationPageLoading = useSelector((state) =>
    get(state, "prismic.page.loading", true)
  );
  const educationPage = useSelector((state) =>
    get(state, "prismic.page.data", false)
  );

  const richContent = get(page, "rich_content");
  const pageTitle = get(page, "page_title");
  const backgroundImage = get(
    educationPage,
    "hero_section[0].background_image.url"
  );
  const mobileBackgroundImage = get(
    educationPage,
    "hero_section[0].mobile_background_image.url"
  );

  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "education_post",
      id: pagePath,
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "page",
      id: "education",
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "global_slices",
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      loadingFunc("yas");
    }
  }, [pagePath, pageLoading, loadingFunc]);

  if (pageLoading) {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "black",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!page) {
    return <Redirect to={"/404"} />;
  }
  return (
    !pageLoading &&
    !educationPageLoading &&
    educationPage &&
    page && (
      <div className={`animation ${animation}`}>
        <Hero
          richContent={pageTitle}
          backgroundImage={backgroundImage}
          mobileBackgroundImage={mobileBackgroundImage}
        />
        <div className="rich-text-slice standard-padding">
          <div className="wrapper">
            <PrismicRichText render={richContent} />
          </div>
        </div>

        {!globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )}
      </div>
    )
  );
}
