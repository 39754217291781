import React, {useState} from "react";
import PrismicRichText from "../components/PrismicRichText";
import {Redirect} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "../components/Link";

export default function TextBackgroundSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));
  const [searchLocation, setSearchLocation] = useState(false);

  const backgroundStyle = {
    backgroundImage: `url(${
      isMobile
        ? slice.primary.mobile_background_image.url
        : slice.primary.desktop_background_image.url
    })`,
    color: slice.primary.text_color,
  };

  const handleForm = (e) => {
    e.preventDefault();
    setSearchLocation(e.target.zip.value);
  };

  const renderRedirect = (searchLocation) => {
    return (
      <Redirect
        push
        to={{
          pathname: "/find-us",
          state: {searchLocation: searchLocation},
        }}
      ></Redirect>
    );
  };

  return (
    <section
      className="text-background-slice find-us-slice standard-padding"
      style={backgroundStyle}
    >
      {searchLocation && renderRedirect(searchLocation)}
      <div
        className={`wrapper ${
          slice.primary.text_box_alignment === "Right" && "align-right"
        }`}
      >
        <div className="text-content">
          <PrismicRichText render={slice.primary.rich_content} />
          <form className="search-form" onSubmit={handleForm}>
            <input
              type="text"
              className="search-input"
              placeholder="Zip Code"
              name="zip"
            ></input>
            <input
              type="submit"
              value="Where to Buy"
              className="button solid"
            ></input>
            <span></span>
          </form>
          <br></br>
          <h2>Buy Online</h2>
          <Link className="button solid" to="/shop">
            Shop Now
          </Link>
        </div>
      </div>
    </section>
  );
}
