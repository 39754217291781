import React from "react";
import {RichText, Link} from "prismic-reactjs";
import linkResolver from "./../helpers/linkResolver";
import isValidLink from "./../helpers/isValidLink";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import renderButtons from "../helpers/renderButtons";

export default function TextBackgroundSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const backgroundStyle = {
    backgroundImage: `url(${
      isMobile
        ? slice.primary.mobile_background_image.url
        : slice.primary.desktop_background_image.url
    })`,
    color: slice.primary.text_color,
  };

  return (
    <section
      className="text-background-slice standard-padding"
      style={backgroundStyle}
    >
      <div
        className={`wrapper ${
          slice.primary.text_box_alignment === "Right" && "align-right"
        }`}
      >
        <div className="text-content">
          <RichText render={slice.primary.rich_content} />
          <div className="buttons">{renderButtons(slice.items)}</div>
        </div>
      </div>
    </section>
  );
}
