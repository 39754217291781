import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function QuestionsSlice(slice) {
  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const renderSlides = (slides) => {
    return slides.map((el, i) => (
      <div className="slide" key={i}>
        <div className="image-content">
          <img src={el.image.url} alt="Slide" />
        </div>
        <div className="text-content">
          <div className="desktop-copy">
            <PrismicRichText render={slice.primary.rich_content} />
          </div>
          <div>
            <PrismicRichText render={el.rich_content} />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <section
      className="questions-slice standard-padding"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="mobile-copy center-align">
          <PrismicRichText render={slice.primary.rich_content} />
        </div>

        <div className="slider">
          <Carousel
            className="questions-carousel"
            showArrows={true}
            showThumbs={false}
            showIndicators={true}
            showStatus={false}
          >
            {renderSlides(slice.items)}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
