import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import renderButtons from "../helpers/renderButtons";

export default function TextSlice(slice) {
  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
  };

  return (
    <section className="text-slice standard-padding" style={backgroundStyle}>
      <div className="wrapper">
        {slice.primary.icon.url && (
          <div className="icon">
            <img src={slice.primary.icon.url} alt="Icon"></img>
          </div>
        )}
        <div
          className={`text-content ${
            !slice.primary.full_width ? "constrained" : "constrained-large"
          }`}
        >
          <PrismicRichText render={slice.primary.rich_content} />
          <div className="buttons buttons-container">
            {renderButtons(slice.items)}
          </div>
        </div>
      </div>
    </section>
  );
}
