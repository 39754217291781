import React from "react";

export default function VideoSlice(slice) {
  const videoURL = slice.primary.video_url.url;
  const videoPosterImage = slice.primary.video_poster.url;

  return (
    <div className="video-slice">
      <video
        controls
        poster={videoPosterImage}
        controlsList="nodownload"
        muted
        loop
        id="home-video"
      >
        <source src={videoURL} type="video/mp4" />
      </video>
    </div>
  );
}
