import React from "react";
import TextSlice from "./TextSlice";
import FullWidthImageSlice from "./FullWidthImageSlice";
import BenefitsSlice from "./BenefitsSlice";
import QuestionsSlice from "./QuestionsSlice";
import TextBackgroundSlice from "./TextBackgroundSlice";
import FindUsSlice from "./FindUsSlice";
import PhotoSection from "./PhotoSection";
import EducationSlice from "./EducationSlice";
import ProductsSlice from "./ProductsSlice";
import FAQSlice from "./FAQSlice";
import VideoSlice from "./VideoSlice";
import PDFSlice from "./PDFSlice";

export default function Slices(props) {
  if (!document) {
    return null;
  }
  return props.slices.map((slice, index) => (
    <div key={index}>
      {slice.slice_type === "text_slice" && TextSlice(slice)}
      {slice.slice_type === "full_width_image_slice" &&
        FullWidthImageSlice(slice)}
      {slice.slice_type === "benefits_slice" && BenefitsSlice(slice)}
      {slice.slice_type === "questions_slice" && QuestionsSlice(slice)}
      {slice.slice_type === "full_width_text_image_slice" && TextBackgroundSlice(slice)}
      {slice.slice_type === "find_us_slice" && FindUsSlice(slice)}
      {slice.slice_type === "photo_section" && PhotoSection(slice)}
      {slice.slice_type === "education_slice" && EducationSlice(slice)}
      {slice.slice_type === "products_slice" && ProductsSlice(slice)}
      {slice.slice_type === "faq_slice" && FAQSlice(slice)}
      {slice.slice_type === "video_slice" && VideoSlice(slice)}
      {slice.slice_type === "pdf_slice" && PDFSlice(slice)}

    </div>
  ));
}
