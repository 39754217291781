import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://remedithc.cdn.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5YNXNVSnhBQUFDRUFTQ0VM.bu-_ve-_ve-_vQRt77-977-977-977-9SRPvv70H77-977-9LiMm77-977-977-9Xw8_77-977-9J--_vRTvv71t';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
