import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const [navOpen, setNavOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const componentLoading = useSelector((state) =>
    get(state, "prismic.header.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.header.data", false)
  );

  const logo = get(component, "logo.url", false);
  const navigationLinks = get(component, "navigation", false);

  const renderNavigation = (navigationLinks) => {
    return navigationLinks.map((el, i) => {
      return (
        <Link
          onClick={() => {
            setNavOpen(false);
          }}
          key={i}
          to={`/${el.link_url.uid}`}
          className={
            props.activePage === `/${el.link_url.uid}` ? "active" : undefined
          }
        >
          {el.link_text[0].text}
        </Link>
      );
    });
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const renderShopButton = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={"shop-dropdown"}
        >
          Shop
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to={"/shop"}>Buy Online</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={"/find-us"}>Store Locator</Link>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    !componentLoading &&
    component && (
      <>
        <div className="header-spacer"></div>

        <div className="header-wrapper">
          <header>
            <div className="wrapper">
              <Link
                to="/"
                className={`logo-container ${navOpen ? "hide" : null}`}
              >
                <img id="logo" className="logo" src={logo} alt="Brand Logo" />
              </Link>

              {isMobile ? (
                <>
                  <IconButton
                    onClick={toggleNav}
                    className="menu-icon"
                    edge="end"
                    aria-label="menu open"
                  >
                    <MenuIcon htmlColor="#403a60" />
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={navOpen}
                    className="nav-drawer"
                    // onClose={toggleDrawer(anchor, false)}
                  >
                    <div className="nav-drawer-content">
                      <IconButton
                        onClick={toggleNav}
                        className="menu-icon-close"
                        edge="end"
                        aria-label="menu close"
                      >
                        <CloseIcon htmlColor="#ffffff" />
                      </IconButton>

                      {renderNavigation(navigationLinks)}
                      <Link
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        to={`/shop`}
                        className={
                          props.activePage === `/shop` ? "active" : null
                        }
                      >
                        Buy Online
                      </Link>
                      <Link
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        to={`/find-us`}
                        className={
                          props.activePage === `/find-us` ? "active" : null
                        }
                      >
                        Store Locator
                      </Link>
                    </div>
                  </Drawer>
                </>
              ) : (
                <div className="desktop-navigation">
                  {renderNavigation(navigationLinks)}
                  {renderShopButton()}
                </div>
              )}
            </div>
          </header>
        </div>
      </>
    )
  );
}
