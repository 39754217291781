import React from "react";
import PrismicPage from "./pages/PrismicPage";
import EducationPage from "./pages/EducationPage";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ShopPage from "./pages/ShopPage";
import StoreLocatorPage from "./pages/StoreLocatorPage";

export default function Routes({ loading, animation, isStaging }) {
  const { pathname } = useLocation();
  const prismicPage = () => {
    return <PrismicPage loading={loading} animation={animation} />;
  };

  const educationPage = () => {
    return <EducationPage loading={loading} animation={animation} />;
  };

  const shopPage = () => {
    return <ShopPage loading={loading} animation={animation} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/find-us">
        <Redirect to="/store-locator" />
      </Route>
      <Route exact path="/store-locator" component={StoreLocatorPage} />
      <Route path="/education/:id" render={educationPage} />
      <Route exact path="/shop" render={shopPage} />
      <Route path="/:id" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
