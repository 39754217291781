import { createTheme } from "@material-ui/core/styles";
const themeObj = {
  palette: {
    primary: {
      main: "#403a60",
    },
    secondary: {
      main: "#605790",
    },
  },
  typography: {
    fontFamily: '"Fran Regular", serif',
  },

  overrides: {
    MuiSwitch: {
      root: {
        padding: "0",
        borderRadius: "18px",
        width: "53px",
        height: "24px",
      },
      thumb: {
        width: "18px",
        height: "18px",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      },
      switchBase: {
        padding: "3px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "white",
        },
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "black",
        },
        "&&&&$checked + &&&&$disabled + $track": {
          opacity: ".2",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: '"Fran Regular", serif',
      },
    },
    MuiFormLabel: {
      root: {
        marginBottom: "15px",
        color: "#000000",
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: ".8rem",
      },
    },
    MuiButton: {
      root: {
        fontFamily: '"Averta Bold", sans-serif',
        fontSize: "16px",
        lineHeight: "20px",
        padding: "14px 21px",
        borderRadius: "50px",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: '"neue-haas-unica", sans-serif !important',
        fontWeight: "900",
        textTransform: "uppercase",
        "& a": {
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "0",
      },
    },
  },
};
const theme = createTheme(themeObj);
export { theme, themeObj };
